<template>
  <div class="page">
    <div class="container clearfix">
      <!-- 左边菜单栏 -->
      <div class="navigation">
        <div class="navigation_user">
          <img :src="userInfo.icon? userInfo.icon : require(`@/assets/img/defaultavatar.png`)" alt />
          <div>{{ userInfo.nickName }}</div>
        </div>
        <ul>
          <li
            v-for="(item, index) in navigation"
            :key="index"
            @click="optionButs(index, item.path)"
            :class="optionClass == index ? 'navigation_select' : ''"
          >
            <i class="iconfont" :class="item.ico"></i>
            {{ item.text }}
          </li>
        </ul>
      </div>
      <!-- 中间内容 -->
      <div class="content clearfix">
        <components
          :is="componentName"
          @editInfo="editInfo"
          @bindingMobile="bindingMobile"
          @editPwd="editPwd"
          @setUpPwd="setUpPwd"
          @detailsBut="detailsBut"
          @editUserInfo="editUserInfo"
          @classCurriculum="classCurriculum"
        ></components>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/personal";
export default {
  components: {
    //账号设置
    accountSettings: () => import("@/components/myInfo/accountSettings"),
    //个人信息编辑
    editMyInfo: () => import("@/components/myInfo/editMyInfo"),
    // 我的课程
    myLesson: () => import("@/components/myLesson/index"),
    // 我的班级
    myClass: () => import("@/components/myClass/index"),
    //绑定手机
    bindingMobile: () => import("@/components/myInfo/bindingMobile"),
    //修改密码
    editPwd: () => import("@/components/myInfo/editPwd"),
    //设置密码
    setUpPwd: () => import("@/components/myInfo/setUpPwd"),
    // 我的消息
    message: () => import("@/components/myMessage/message"),
    //消息详情
    messageDetails: () => import("@/components/myMessage/messageDetails"),
    //我的考试
    test: () => import("@/components/test/index"),
    // 考试
    examination: () => import("@/components/examination/index"),
    //我的收藏
    myCollection: () => import("@/components/myCollection/index"),
    //查看考卷
    viewExamination: () => import("@/components/viewExamination/index"),
    //查看班级课程
    classDetail: () => import("@/components/classDetail/index"),
  },
  data() {
    return {
      userInfo: {},
      optionClass: 0, //切换的颜色
      navigation: [
        {
          id: 0,
          ico: "iconweibiaoti-1_huaban1fuben",
          text: "我的课程",
          path: "myLesson",
        },
        {
          id: 1,
          ico: "iconweibiaoti-1_huaban1fuben4",
          text: "我的班级",
          path: "myClass",
        },
        {
          id: 2,
          ico: "iconweibiaoti-1_huaban1fuben3",
          text: "我的收藏",
          path: "myCollection",
        },
        {
          id: 3,
          ico: "iconweibiaoti-1_huaban1fuben6",
          text: "我的考试",
          path: "test",
        },
        {
          id: 4,
          ico: "iconweibiaoti-1_huaban1fuben5",
          text: "我的消息",
          path: "message",
        },
        {
          id: 5,
          ico: "iconweibiaoti-1_huaban1fuben2",
          text: "账号设置",
          path: "accountSettings",
        },
      ], //菜单栏
      componentName: "myLesson",
    };
  },
  watch: {
    $route: function () {
      this.getParameter();
    },
  },
  created() {
    this.getParameter();
    this.getUserInfo();
  },
  methods: {
    //左侧导航栏点击
    optionButs(e, path) {
      this.$router.push({ path: "myStudy", query: { option: e, path: path } });
      this.componentName = path;
      this.optionClass = e;
    },
    //获取个人信息
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.userInfo = res.data;
          this.$store.commit("SET_PERSONALINFO", res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //个人信息编辑
    editInfo() {
      this.pageJump(5, "editMyInfo");
    },
    //绑定手机
    bindingMobile() {
      this.pageJump(5, "bindingMobile");
    },
    //修改密码
    editPwd() {
      this.pageJump(5, "editPwd");
    },
    //设置密码
    setUpPwd() {
      this.pageJump(5, "setUpPwd");
    },
    //消息详情
    detailsBut(id) {
      this.pageJumpId(4, "messageDetails", id);
    },
    //修改完个人信息刷新
    editUserInfo() {
      this.getUserInfo();
    },
    //班级课程
    classCurriculum(obj) {
      this.$router.push({
        path: "myStudy",
        query: {
          option: 1,
          path: "classDetail",
          id: obj.id,
          title: obj.className,
        },
      });
    },
    //获取页面参数
    getParameter() {
      let pageId = this.$route.query.option || "0";
      let path = this.$route.query.path || "myLesson";
      this.optionClass = pageId;
      this.componentName = path;
    },
    //点击按钮跳转
    pageJump(number, url) {
      this.optionClass = number;
      this.componentName = url;
      this.$router.push({
        path: "myStudy",
        query: { option: number, path: url },
      });
    },
    //点击按钮跳转带参数
    pageJumpId(number, url, id) {
      this.optionClass = number;
      this.componentName = url;
      this.$router.push({
        path: "myStudy",
        query: { option: number, path: url, id: id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 808px;
  background: #f3f3f3;
  padding-top: 30px;
  padding-bottom: 50px;
}

.container {
  // overflow: visible;
  position: relative;
}

.navigation {
  width: 220px;
  height: 748px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px 0;
  float: left;
  & > .navigation_user {
    margin-top: 23px;
    text-align: center;
    & > img {
      width: 72px;
      height: 72px;
      border-radius: 50%;
    }
    & > div {
      margin-top: 15px;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      line-height: 16px;
    }
  }
  & > ul {
    padding: 0;
    margin-top: 51px;
    text-align: left;
    & > li {
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
      margin-left: 3px;
      margin-bottom: 8px;
      & > .iconfont {
        margin-left: 24px;
        margin-right: 11px;
        font-size: 20px;
      }
    }
    & > .navigation_select {
      background: #f6f6f6;
      border-left: 3px solid #0d79ff;
      color: #0d79ff;
      margin-left: 0;
    }
  }
}

//中间内容
.content {
  margin-left: 16px;
  background: #ffffff;
  width: 964px;
  // height: 748px;
  border-radius: 6px;
  margin-bottom: 50px;
  // padding-bottom: 20px;
  float: right;
}

//右侧菜单
.menu {
  position: relative;
  top: 369px;
  width: 90px;
  height: 257px;
  background: #ffffff;
  border: 1px solid #dedede;
  left: 102%;
  & > ul {
    position: absolute;
    left: 10px;
    top: 9px;
    & > li {
      text-align: -webkit-center;
      margin-bottom: 19px;
      cursor: pointer;
      & > .iconfont {
        font-size: 25px;
      }
      & > .join_class {
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        line-height: 14px;
        margin-top: 7px;
      }
      & > .menu_line {
        width: 60px;
        height: 1px;
        background: #eeeeee;
        margin-top: 18px;
      }
    }
  }
  & > .menu_div {
    width: 88px;
    height: 80px;
    background: #ffffff;
    line-height: 80px;
    cursor: pointer;
    position: absolute;
    top: 270px;
    & > .iconfont {
      font-size: 25px;
    }
  }
}
</style>